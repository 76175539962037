import React, { useState } from "react"
import styled from "@emotion/styled"

import PageContent from "../components/shared/PageContent"
import SEO from "../components/SEO"
import TransError from "../components/shared/TransError"
import { breakpoints, fonts, colors } from "../utils/styles"
import { ChatIcon, EditIcon } from "../assets/icons"

const FeedbackPage = () => {
  const [feedback, setFeedback] = useState(true)

  return (
    <>
      <SEO title="Feedback" />
      <TransError />
      <PageContent>
        <ToggleWrapper>
          <Button
            className={feedback ? `active naked-btn` : `naked-btn`}
            onClick={() => setFeedback(true)}
          >
            <ButtonTitle>
              <ChatIcon />
              <h3>Feedback</h3>
            </ButtonTitle>
            <div>Send your ideas and opinions</div>
          </Button>
          <Button
            className={!feedback ? `active naked-btn` : `naked-btn`}
            onClick={() => setFeedback(false)}
          >
            <ButtonTitle>
              <EditIcon />
              <h3>Contribute</h3>
            </ButtonTitle>
            <div>Correct an error or submit a translation</div>
          </Button>
        </ToggleWrapper>
        {feedback ? (
          <Frame
            className="airtable-embed"
            src="https://airtable.com/embed/shrlhDRyqw4lKvFaA?backgroundColor=blue"
            width="100%"
            height="785"
          />
        ) : (
          <Frame
            className="airtable-embed"
            src="https://airtable.com/embed/shrkgMGasZksfG5IU?backgroundColor=purple"
            width="100%"
            height="1000"
          />
        )}
      </PageContent>
    </>
  )
}

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 50px;
  margin-bottom: 50px;

  button:hover,
  .active {
    background: #fbfbfb;
    color: ${colors.brand};
    box-shadow: 5px 2px 4px 0px rgba(0, 0, 0, 0.03);

    svg {
      fill: ${colors.brand};
    }
  }

  @media (min-width: ${breakpoints.tablet}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Button = styled.button`
  flex-direction: column;
  flex: 1;
  font-family: ${fonts.heading};
  color: #2d2d2d;
  border: 1px solid #dedede;
  background: #fff;
  padding: 30px 20px;
  border-radius: 3px;
  transition: all ease 0.3s;

  h3 {
    margin-bottom: 0;
  }
`

const ButtonTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    height: 28px;
    margin-right: 10px;
    transition: all ease 0.3s;
  }
`

const Frame = styled.iframe`
  border: 1px solid #dedede;
  border-radius: 3px;
  background: transparent;
  box-shadow: 5px 5px 13px -2px rgba(0, 0, 0, 0.06);
`

export default FeedbackPage
